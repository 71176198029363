<template>
  <path
    d="M11.3802 2.98813C10.2065 2.50132 8.91508 2.37396 7.66918 2.62212C6.42327 2.87028 5.27871 3.48285 4.38029 4.3825C3.48186 5.28216 2.86992 6.42849 2.622 7.67658C2.37407 8.92469 2.50133 10.2184 2.98763 11.394C3.47392 12.5696 4.29738 13.5743 5.35374 14.2811C6.41009 14.9879 7.65192 15.3651 8.92222 15.3651C9.2674 15.3651 9.54722 15.6449 9.54722 15.9901C9.54722 16.3353 9.2674 16.6151 8.92222 16.6151C7.40432 16.6151 5.92058 16.1644 4.65862 15.32C3.39668 14.4757 2.41325 13.2756 1.83255 11.8718C1.25185 10.468 1.09993 8.92328 1.39595 7.43304C1.69198 5.94279 2.42269 4.5738 3.49581 3.49922C4.56893 2.42463 5.93629 1.69273 7.425 1.3962C8.91371 1.09968 10.4568 1.25187 11.8591 1.83351C13.2614 2.41514 14.4598 3.40005 15.3029 4.66354C16.146 5.92703 16.5959 7.4124 16.5959 8.93181C16.5959 9.27699 16.3161 9.55681 15.9709 9.55681C15.6257 9.55681 15.3459 9.27699 15.3459 8.93181C15.3459 7.65924 14.969 6.41532 14.2631 5.35735C13.5571 4.29939 12.5539 3.47494 11.3802 2.98813Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.61197 8.75808C9.81188 8.73157 10.0151 8.7655 10.1956 8.85546L10.1965 8.85594L18.1927 12.8595C18.376 12.9498 18.527 13.0943 18.6254 13.2733C18.7234 13.4497 18.765 13.6519 18.7448 13.8527C18.725 14.0544 18.6443 14.2453 18.5133 14.3999C18.3765 14.5658 18.1906 14.6842 17.982 14.7379L17.9778 14.739L15.3738 15.3909L14.7226 17.999L14.7222 18.0008C14.6724 18.1974 14.5627 18.3735 14.4083 18.5046C14.2553 18.6414 14.0624 18.7256 13.8577 18.7446C13.6632 18.7644 13.4673 18.7256 13.2953 18.6335C13.1066 18.5396 12.9548 18.3852 12.864 18.195L8.85994 10.1908C8.77026 10.0105 8.73648 9.80744 8.76288 9.60778L8.7631 9.60616C8.79202 9.39172 8.89046 9.19258 9.04346 9.03937C9.19648 8.88614 9.39561 8.78734 9.61034 8.75829L9.61197 8.75808ZM10.3201 10.3157L17.0466 13.6836L14.7064 14.2694C14.4825 14.3254 14.3077 14.5003 14.2518 14.7243L13.6771 17.0264L10.3201 10.3157Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.7943 5.58332C9.20921 5.42995 8.59432 5.43205 8.0103 5.58939L5.58558 9.83233C5.74241 10.4177 6.05024 10.9514 6.47807 11.3798C6.9059 11.8082 7.43867 12.1163 8.02285 12.2732C8.35621 12.3628 8.55385 12.7056 8.4643 13.039C8.37475 13.3723 8.03191 13.57 7.69855 13.4804C6.90231 13.2665 6.17638 12.8467 5.59359 12.2631C5.0108 11.6795 4.59166 10.9527 4.37816 10.1558C4.05581 8.95236 4.22192 7.67004 4.84036 6.58871C5.45881 5.50734 6.4795 4.71462 7.67983 4.38386L7.68299 4.38299C8.47784 4.16845 9.31486 4.16542 10.1112 4.37417C10.9076 4.58292 11.6357 4.99622 12.2235 5.57308L12.226 5.57557C12.8126 6.15787 13.2369 6.88363 13.4566 7.68073C13.5484 8.01349 13.353 8.35763 13.0203 8.44938C12.6875 8.54113 12.3434 8.34576 12.2516 8.013C12.0899 7.42642 11.7779 6.89246 11.3467 6.46399C10.9144 6.04019 10.3793 5.73668 9.7943 5.58332ZM5.92543 7.20928C6.37984 6.41475 7.12934 5.83259 8.0103 5.58939L5.58558 9.83233C5.34852 8.9473 5.47073 8.00432 5.92543 7.20928Z"
    fill="currentColor" />
</template>
